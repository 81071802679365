import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route } from 'react-router-dom';
import Admin from 'pages/home';

//Private - Admin
import AdminLayout from 'layouts/admin';
import Posts from 'pages/admin/posts/posts';
import Tutorials from 'pages/admin/tutorials/tutorials';
import Versions from 'pages/admin/versions';
import Categories from 'pages/admin/categories/categories';
import Tags from 'pages/admin/tags/tags';
import Authors from 'pages/admin/authors/authors';
import Announcements from 'pages/admin/announcements/announcements';
import Users from 'pages/admin/users';
import Teams from 'pages/admin/users/teams';
import Sales from 'pages/admin/users/sales';
import Downgrade from 'pages/admin/users/downgrade';

import TeamsOLD from 'pages/admin/users/gumroad';

import Usage from 'pages/admin/usage';
import Billings from 'pages/admin/billings';
import Reports from 'pages/admin/reports';
import Surveys from 'pages/admin/surveys';
import Contacts from 'pages/admin/contacts/contacts';
import Appsumos from 'pages/admin/appsumos';
import GumroadsWebhook from 'pages/admin/gumroad';
import SuperUsers from 'pages/admin/superusers';
import Enterprise from 'pages/admin/enterprise';
import EnterpriseDowngrade from 'pages/admin/enterprise/downgrade';

import useAuth from 'hooks/useAuth';
import SidebarLayout from 'layouts/sidebar';
import ForgotPassword from 'pages/forgotPassword';
import Systeme from 'pages/admin/systeme';
import UserUsageDetail from 'pages/admin/users/usage-detail';

const App = () => {
  const { user } = useAuth();

  return (
    <>
      <Helmet>
        <title>Admin</title>
      </Helmet>
      <Routes>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route index={true} path="/" element={<Admin />} />
        <Route path="v1" element={<AdminLayout />}>
          {user?.role === "super" && (
            <>
              <Route index element={<Users />} />
              <Route path="users">
                <Route index element={<Users />} />
                <Route path="teams" element={<Teams />} />
                <Route path="sales" element={<Sales />} />
                <Route path="downgrade" element={<Downgrade />} />
                <Route path="gumroad" element={<TeamsOLD />} />
                <Route path="usage-detail/:userId" element={<UserUsageDetail />} />
              </Route>
              <Route path="usages">
                <Route index element={<Usage />} />
              </Route>
              <Route path="billings" element={<Billings />} />
              <Route path="posts" element={<Posts />} />
              <Route path="master" element={<SidebarLayout role={user?.role} />}>
                <Route path="tutorials" element={<Tutorials />} />
                <Route path="versions" element={<Versions />} />
                <Route path="categories" element={<Categories />} />
                <Route path="tags" element={<Tags />} />
                <Route path="authors" element={<Authors />} />
                <Route path="announcements" element={<Announcements />} />
                <Route path="superusers" element={<SuperUsers />} />
              </Route>
              <Route path="reports" >
                <Route index element={<Reports />} />
              </Route>
              <Route path="surveys" >
                <Route index element={<Surveys />} />
              </Route>
              <Route path="contacts" element={<Contacts />} />
              <Route path="appsumo" element={<Appsumos />} />
              <Route path="enterprise" element={<Enterprise />} />
              <Route path="systeme" element={<Systeme />} />

              <Route path="enterprise">
                <Route index element={<Enterprise />} />
                <Route path="downgrade" element={<EnterpriseDowngrade />} />
              </Route>
              <Route path="gumroad" >
                <Route index element={<GumroadsWebhook />} />
              </Route>
            </>
          )}

          {user?.role === "sale" && (
            <>
              <Route index element={<Users />} />
              <Route path="users" element={<Users />} />
              <Route path="usages">
                <Route index element={<Usage />} />
              </Route>
              <Route path="billings" element={<Billings />} />
              <Route path="contacts" element={<Contacts />} />
              <Route path="appsumo" element={<Appsumos />} />
              <Route path="enterprise" element={<Enterprise />} />
              <Route path="gumroad" >
                <Route index element={<GumroadsWebhook />} />
              </Route>
            </>
          )}

          {user?.role === "support" && (
            <>
              <Route path="usages">
                <Route index element={<Usage />} />
              </Route>
              <Route path="reports" >
                <Route index element={<Reports />} />
              </Route>
            </>
          )}

          {user?.role === "content" && (
            <>
              <Route path="posts" element={<Posts />} />
            </>
          )}

          {user?.role === "reseller" && (
            <>
              <Route path="enterprise" element={<Enterprise />} />
            </>
          )}
        </Route>
      </Routes>
    </>
  );
};

export default App;
